@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Our dark theme
.ttvn-theme {
    color: white;
    $ttvn-primary: mat.define-palette(mat.$blue-palette);
    $ttvn-accent: mat.define-palette(mat.$amber-palette, A400, A100, A700);
    $ttvn-warn: mat.define-palette(mat.$red-palette);
    $ttvn-theme: mat.define-light-theme($ttvn-primary, $ttvn-accent, $ttvn-warn);

    @include mat.all-legacy-component-themes($ttvn-theme);
}

