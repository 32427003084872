@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
//@import '@angular/material/theming';
//$custom-typography: mat-typography-config($font-family: 'Arial, Helvetica, sans-serif');
//@include mat.core($custom-typography);

// Our dark theme
.lansochriksnytt-theme {
    color: white;
    $lansochriksnytt-primary: mat.define-palette(mat.$gray-palette, 50, 100, 200);
    $lansochriksnytt-accent: mat.define-palette(mat.$amber-palette, A400, A100, A700);
    $lansochriksnytt-warn: mat.define-palette(mat.$red-palette);
    $lansochriksnytt-theme: mat.define-light-theme($lansochriksnytt-primary, $lansochriksnytt-accent, $lansochriksnytt-warn);

    @include mat.all-legacy-component-themes($lansochriksnytt-theme);
}

