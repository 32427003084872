/* You can add global styles to this file, and also import other style files */


@import './themes/default.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

html,
body {
  height: auto;
  // height: 100%;
  //     height: calc(100vh - 200px);
}

body {
  margin: 0;
  font-family: sans-serif;
}

.radioockero-theme {
  height: calc(100vh - 200px) !important;
}

.ybladet-theme,
.ttvn-theme,
.insyn-theme {
  //height: calc(100vh - 120px) !important;
}

.ybladet-theme {
  font-family: sans-serif;

  .header-wrapper {
    background-color: #ffeb3b69;
  }

  .top-header {
    background-color: unset;
    //background-color: rgb(76, 187, 235);

    .top-header-menu {
      .menu-item {

        //margin: 10px;
        .mat-flat-button {
          background-color: burlywood;
        }
      }
    }
  }
}

.insyn-theme {
  font-family: sans-serif;

  .header-wrapper {
    background-color: rgb(0, 98, 164);
  }

  .top-header {
    background-color: unset;

    .top-header-menu {
      .menu-item {

        //margin: 10px;
        .mat-flat-button {
          background-color: white;
        }
      }
    }
  }
}



.ttvn-theme {
  font-family: sans-serif;

  .header-wrapper {
    background-color: rgb(76, 187, 235);
  }

  .top-header {
    background-color: unset;
    //background-color: rgb(76, 187, 235);

    .top-header-menu {
      .menu-item {

        //margin: 10px;
        .mat-flat-button {
          background-color: white;
        }
      }
    }
  }
}

.lansochriksnytt-theme {
  background-color: white;

  .mat-app-background {
    background-color: white;
  }

  .header-wrapper {
    background-color: black;
  }

  .mat-flat-button {
    background-color: #ffe1e1df;
  }

  .top-header {
    background-color: black;

    .top-header-menu {
      .menu-item {

        //margin: 10px;
        .mat-flat-button {
          background-color: #ffe1e1df;
        }
      }
    }
  }
}

.regionfullmaktige-theme {
  background-color: white;

  .mat-app-background {
    background-color: white;
  }

  .header-wrapper {
    background-color: #032E48;
  }

  .mat-flat-button {
    background-color: #ffe1e1df;
  }

  .top-header {
    background-color: #032E48;
  }
}

.huddinge-theme {
  background-color: white;

  .mat-app-background {
    background-color: white;
  }

  .header-wrapper {
    background-color: orange;
  }

  .top-header {
    background-color: orange;

    .top-header-menu {
      .menu-item {

        //margin: 10px;
        .mat-flat-button {
          background-color: antiquewhite;
        }
      }
    }
  }


}

.radioockero-theme .mat-toolbar.mat-primary {
  background-image: url("/assets/ockero_ny2.jpg");

  .toolbar-image {
    margin-left: 5px;
    background-color: antiquewhite;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 3px;
  }
}

.main {
  width: 90%;
  margin: auto;
}

table {
  width: 100%;
}

.ljp-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ljp-form-field-450 {
    width: 450px;
    max-width: 80%;
  }

  .ljp-form-field-450-top-margin {
    margin-top: 20px;
    width: 450px;
    max-width: 80%;
  }

  .ljp-form-field-100 {
    width: 100%;
  }

  .ljp-form-field-100p-top-margin {
    margin-top: 7px;
    width: 100%;
  }
}

.ljp-icon {
  padding: 0 14px;
}

.large-icon-150 {
  height: 150px !important;
  width: 150px !important;
  font-size: 150px !important;
}

.ljp-spacer {
  flex: 1 1 auto;
}

.ljp-card {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.mat-column-small {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 12% !important;
  width: 12% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

mat-form-field {
  &.top-menu-search {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 0px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 5px;
  border-radius: 2px
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  // background-color: rgb(255, 225, 225);
  
}

.mat-warn .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: white;
  border: solid 2px grey;
  bottom: -16px;
  right: -20px;
}

.mat-warn .mat-slider.mat-slider-disabled .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: white; 
  border: solid 2px grey;
  bottom: -16px;
  right: -20px;
}

.ql-container {
  font-size: large !important;
}

.article-text {
  .ql-editor {
    overflow: hidden !important;
  }
}
