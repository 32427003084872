@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$ybladet-palette: (
    50: #eaf7fd,
    100: #c9ebf9,
    200: #a6ddf5,
    300: #82cff1,
    400: #67c5ee,
    500: #4cbbeb,
    600: #45b5e9,
    700: #3cace5,
    800: #33a4e2,
    900: #2496dd,
    A100: #ffffff,
    A200: #e4f4ff,
    A400: #b1dfff,
    A700: #97d5ff,
    contrast: (
        50: black,
        100: black,
        200: black,
        300: black,
        400: black,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: black,
        A200: black,
        A400: black,
        A700: white,
    )
);

// Our dark theme
.ybladet-theme {
    color: white;
    $ybladet-primary: mat.define-palette($ybladet-palette);
    $ybladet-accent: mat.define-palette(mat.$amber-palette, A400, A100, A700);
    $ybladet-warn: mat.define-palette(mat.$red-palette);
    $ybladet-theme: mat.define-light-theme($ybladet-primary, $ybladet-accent, $ybladet-warn);

    @include mat.all-legacy-component-themes($ybladet-theme);
}

